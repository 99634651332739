import { getEditorAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import type {
	InlineCommentTopLevelMarkerRefsLocationType,
	InlineCommentTopLevelMarkerRefsType,
} from '@confluence/inline-comments-queries';

const getElementOffsetFromTopOfPage = (element: HTMLElement) => {
	let elementOffsetTop = 0;
	let nodeToMeasure = element;

	while (nodeToMeasure && !nodeToMeasure.classList.contains('fabric-editor-popup-scroll-parent')) {
		elementOffsetTop += nodeToMeasure.offsetTop;
		nodeToMeasure = nodeToMeasure.offsetParent as HTMLElement;
	}

	return elementOffsetTop;
};

// Function to determine if comment is within expand, and open respective expand
export const checkElementExpandInEditor = (element: HTMLElement) => {
	let nodeToCheck = element;
	const expandElementClassname = '.ak-editor-expand';
	let expandButton: HTMLElement | null = null;

	if (nodeToCheck?.closest?.(expandElementClassname)) {
		//find comment's respective expand button
		while (nodeToCheck) {
			if (nodeToCheck.classList.contains('ak-editor-expand')) {
				expandButton = nodeToCheck.querySelector(
					'.ak-editor-expand__icon-container',
				) as HTMLElement;
				break;
			} else {
				nodeToCheck = nodeToCheck?.parentElement as HTMLElement;
			}
		}
		//check if expand is already opened, if not open it
		if (!nodeToCheck.classList.contains('ak-editor-expand__expanded') && expandButton) {
			expandButton.click();
		}
	}
};

export const openInlineCommentInEditor = (
	targetElement: HTMLElement,
	targetElementID: string,
	commentIsAReply = false,
	isUnreadCommentsEnabled = false,
) => {
	const emitter = getEditorAnnotationEventEmitter();

	checkElementExpandInEditor(targetElement);

	emitter.emit('setselectedannotation', targetElementID);

	window.getSelection()?.setPosition(targetElement);

	if (!commentIsAReply && !isUnreadCommentsEnabled) {
		scrollToElementInEditor(targetElement, -100);
	}
};

export const scrollToElementInEditor = (
	targetElement: HTMLElement,
	viewingRoomOffset: number = 0,
) => {
	const editorScrollableContainer = document.querySelector(
		'.fabric-editor-popup-scroll-parent',
	) as HTMLElement;

	if (editorScrollableContainer) {
		editorScrollableContainer.scrollTo(
			0,
			getElementOffsetFromTopOfPage(targetElement) + viewingRoomOffset, // add viewing room
		);
	}
};

type MissedEditorEventsParams = {
	data: InlineCommentTopLevelMarkerRefsType;
	previousAnnotations: string[];
};

export const updateCommentHighlightsInEditor = ({
	data,
	previousAnnotations,
}: MissedEditorEventsParams): string[] => {
	const emitter = getEditorAnnotationEventEmitter();
	const topLevelCommentMarkerRefs = data?.comments?.nodes?.map(
		(node) => (node?.location as InlineCommentTopLevelMarkerRefsLocationType).inlineMarkerRef,
	) as string[];

	if (topLevelCommentMarkerRefs && topLevelCommentMarkerRefs.length > 0) {
		const newAnnotationsSet = new Set(topLevelCommentMarkerRefs);

		const removedMarkerRefs: string[] = [];
		previousAnnotations.forEach((item) => {
			if (!newAnnotationsSet.has(item)) {
				removedMarkerRefs.push(item);
			}
		});

		let updatedAnnotations = [...previousAnnotations];

		const addedMarkerRefs: string[] = [];
		newAnnotationsSet.forEach((item) => {
			if (!previousAnnotations.includes(item)) {
				addedMarkerRefs.push(item);
			}
		});

		for (const markerRef of addedMarkerRefs) {
			emitter.emit('create', markerRef);
			updatedAnnotations.push(markerRef);
		}

		for (const markerRef of removedMarkerRefs) {
			emitter.emit('resolve', markerRef);
			updatedAnnotations = updatedAnnotations.filter((annotation) => annotation !== markerRef);
		}

		return updatedAnnotations;
	}
	return [];
};
