import { AnnotationUpdateEmitter } from '@atlaskit/editor-core';

type AnnotationEventEmitterFn = () => any;

let editorEmitter: AnnotationUpdateEmitter | undefined = undefined;
export const getEditorAnnotationEventEmitter: AnnotationEventEmitterFn = () => {
	if (!editorEmitter) {
		editorEmitter = new AnnotationUpdateEmitter();
	}

	return editorEmitter;
};
